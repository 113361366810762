import { NAME as CardName } from '@spa-ec/components/Card/Card.theme';
import { NAME as PromotionsName } from '@spa-ec/components/Header/HeaderPromotions/Promotions.theme';
import { NAME as OfferBannerName } from '@spa-ec/components/PersonalizedCockpit/OfferBanner/OfferBanner.theme';
import { NAME as ReplacementPanelName, } from '@spa-ec/components/ProductDetails/ReplacementPanel/ReplacementPanel.theme';
import { NAME as MobileDropDownName, } from '@spa-ec/components/Header/HeaderMobileDropDown/MobileDropDown.theme';
import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as CartSummaryName } from '@spa-ec/components/QliroCheckout/CartSummary.theme';
import { NAME as QliroCheckoutName } from '@spa-ec/components/QliroCheckout/QliroCheckout.theme';
import { NAME as ProductDetailsName } from '@spa-ec/components/ProductDetails/ProductDetails.theme';
import { NAME as CategoryListingName } from '@spa-ec/components/CategoryListing/CategoryListing.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as FooterName } from '@spa-ec/components/Footer/Footer.theme';
import { NAME as PasswordRequestName } from '@spa-ec/components/PasswordRequest/PasswordRequest.theme';
import { NAME as ListFinderName } from '@spa-ec/components/ListFinder/ListFinder.theme';
import { NAME as ShipmentAddressFormName, } from '@spa-ec/components/Checkout/AddressSection/ShipmentAddressForm.theme';
import { NAME as ProductRowName } from '@spa-ec/components/ProductTable/Rows/ProductRow.theme';
import { NAME as USPWrapperName } from '@spa-ec/components/ContentSlot/components/USPWrapper/USPWrapper.theme';
import { NAME as CartName } from '@spa-ec/components/Cart/Cart.theme';
import { NAME as PrisjaktCheapestName } from '@spa-ec/components/PrisjaktCheapest/PrisjaktCheapest.theme';
import { NAME as SiteLogoName } from '@spa-ec/components/Header/HeaderSiteLogo/SiteLogo.theme';
import { NAME as PersonalizedBannerName } from '@ui-elem/PersonalizedBanner/PersonalizedBanner.theme';
import theme from './theme.conf';
const contentPageTheme = {
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    sideContentPadding: 'px-4',
};
const cardTheme = {
    showEnKrBackground: true,
};
const promotionsTheme = {
    promotionImagePath: '/_ui/dist/theme-inkclub/images/icons/bullhorn_ebk_inkclub.svg',
};
const offerBannerTheme = {
    enabledOfferBanner: true,
};
const replacementPanelTheme = {
    emvWarrantyImagePath: '/images/icon-Garanti-small.png',
};
const mobileDropDownTheme = {
    contactUsUrl: '/contact_us',
};
const cartSummaryTheme = {
    headerFontSize: 'x1',
    summaryFontSize: 'm',
    summaryFontClass: 'text-m',
    borderInSummary: true,
};
const qliroCheckoutTheme = {
    primaryColor: theme.col.primary.fg,
    callToActionColor: theme.col.primary.fg,
    callToActionHoverColor: theme.col.primary.peer,
};
const productDetailsTheme = {
    productDetailsSeparator: '&emsp;',
    borderSpecificationTable: false,
    productDetailsBoldness: true,
};
const categoryListingTheme = {
    infoTextFontSize: '14px',
    showCategoryBrandLogoList: true,
};
const pageTitleTheme = {
    noBorder: true,
    fg: theme.col.primary.fg,
    bg: 'white',
    showLogoutIcon: false,
    combineSections: true,
    bottomBorderColor: '#343434',
};
const cartTableTheme = {
    showProductDetails: true,
    showPerKronaInCartTable: false,
};
const footerTheme = {
    showNewsLetter: true,
    showSocialMediaLinks: true,
};
const shipmentAddressFormTheme = {
    showShippingAddressCellphone: true,
};
const productRowTheme = {
    showOneKronaSticker: true,
    productCodeColor: theme.col.secondary.fg,
};
const listFinderTheme = {};
const passwordRequestTheme = {
    enablePasswordRequestByCustomerNumber: true,
};
const uspWrapperTheme = {
    maximumItemsIpad: 2,
};
const cartTheme = {};
const prisjaktCheapestTheme = {
    storeIds: {
        'inkclub-se': 1286,
        'inkclub-se-b2b': 1286,
        'inkclub-no': 10858,
        'inkclub-no-b2b': 10858,
        'inkclub-fi': 24745,
        'inkclub-fi-b2b': 24745,
        'inkclub-dk': 36356,
        'inkclub-dk-b2b': 36356,
    },
};
const b2bLogo = '/nordic/common/inkclub_logo_b2b.svg';
const b2cLogo = '/nordic/common/inkclub_logo_b2c.svg';
const siteLogoTheme = {
    siteLogo: {
        imagesRootPath: '/images/sitelogo',
        paths: {
            b2c: {
                se: {
                    sv: b2cLogo,
                },
                no: {
                    no: b2cLogo,
                },
                dk: {
                    da: b2cLogo,
                },
                fi: {
                    sv_FI: b2cLogo,
                    fi: b2cLogo,
                },
                default: b2cLogo,
            },
            b2b: {
                se: {
                    sv: b2bLogo,
                },
                no: {
                    no: b2bLogo,
                },
                dk: {
                    da: b2bLogo,
                },
                fi: {
                    sv_FI: b2bLogo,
                    fi: b2bLogo,
                },
                default: b2bLogo,
            },
        },
    },
};
const personalizedBannerTheme = {
    showEnKrMessage: true,
};
export default {
    [ContentPageName]: contentPageTheme,
    [CardName]: cardTheme,
    [PromotionsName]: promotionsTheme,
    [OfferBannerName]: offerBannerTheme,
    [ReplacementPanelName]: replacementPanelTheme,
    [MobileDropDownName]: mobileDropDownTheme,
    [CartSummaryName]: cartSummaryTheme,
    [QliroCheckoutName]: qliroCheckoutTheme,
    [ProductDetailsName]: productDetailsTheme,
    [CategoryListingName]: categoryListingTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [FooterName]: footerTheme,
    [ShipmentAddressFormName]: shipmentAddressFormTheme,
    [ProductRowName]: productRowTheme,
    [ListFinderName]: listFinderTheme,
    [PasswordRequestName]: passwordRequestTheme,
    [USPWrapperName]: uspWrapperTheme,
    [CartName]: cartTheme,
    [PrisjaktCheapestName]: prisjaktCheapestTheme,
    [SiteLogoName]: siteLogoTheme,
    [PersonalizedBannerName]: personalizedBannerTheme,
};
