import { CategoryListingType } from './constants'

export const NAME: string = 'CategoryListing'

export type CategoryListingTheme = {
    showHighlights?: boolean
    slimProductPayload?: boolean
    listingType?: CategoryListingType
    infoTextFontSize?: string
    forceShowPagination?: boolean
    showCategoryBrandLogoList?: boolean
}
